
import esri_style from './map_style_2_prettified';

mapboxgl.accessToken = process.env.MAPBOX_TOKEN;
mapboxgl.setRTLTextPlugin(
  'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
  null,
  true // Lazy load the plugin
);

const layerTypeForm = document.querySelector('#layer_type');

const map = new mapboxgl.Map({
  container: 'map', // container ID
  style: esri_style,
  center: [47.6498606, 29.3310564], // starting position [lng, lat]
  maxBounds: [
    [46.45169385730522, 28.42494872539779], // Southwest coordinates
    [48.877024969659536, 30.20326220516838], // Northeast coordinates
  ],
  zoom: 8, // starting zoom
  minZoom: 8,
  maxZoom: 20,
  interactive: true,
  bearingSnap: 0,
  pitchWithRotate: false,
  attributionControl: false,
  failIfMajorPerformanceCaveat: true,
  refreshExpiredTiles: false,
  dragRotate: false,
  dragPan: true,
  keyboard: true,
  doubleClickZoom: true,
  touchPitch: false,
  pitch: 0,
  bearing: 0,
  renderWorldCopies: false
});

window.map = map;

map.addControl(new mapboxgl.ScaleControl(), 'bottom-right');
map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');

map.on('load', function () {
  map.loadImage(
    'img/road_number_case.png',
    function (error, image) {
      if (error) throw error;
      map.addImage('road_number_case', image);    
    }
  )
});

layerTypeForm.onchange = function () {
  const typeOne = this['layer_type_1'].value;
  const typeTwo = this['layer_type_2'].value;
  const aqariLayers = map.getStyle().layers.filter(layer => layer.source === 'aqari');

  map.setLayoutProperty('google-satellite', 'visibility', 'none');
  map.setLayoutProperty('esri-satellite', 'visibility', 'none');
  map.setLayoutProperty('aqari-raster', 'visibility', 'none');
  for (const layer of aqariLayers) map.setLayoutProperty(layer.id, 'visibility', 'none');

  if (typeOne === 'vector') {
    for (const layer of aqariLayers) map.setLayoutProperty(layer.id, 'visibility', 'visible');
  } else if (typeOne === 'raster') {
    map.setLayoutProperty('aqari-raster', 'visibility', 'visible');
  }

  if (typeOne === 'vector' && typeTwo === 'google') {
    map.setLayoutProperty('google-satellite', 'visibility', 'visible');
  } else if (typeOne === 'vector' && typeTwo === 'esri') {
    map.setLayoutProperty('esri-satellite', 'visibility', 'visible');
  }

  document.querySelector('.additional-layers').style.display = typeOne === 'vector' ? 'block' : 'none';
}
