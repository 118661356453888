/* eslint-disable no-undef */
// import dotenv from 'dotenv/config';

// console.log('HOST FROM STYLE', process.env.HOST);

var esri_style =
{
    'version': 8,
    // 'sprite': 'https://tiles.arcgis.com/tiles/2zwTmDUxTzTVBytU/arcgis/rest/services/PACIKFBasemap/VectorTileServer/resources/styles/../sprites/sprite',
    'glyphs': 'https://tiles.arcgis.com/tiles/2zwTmDUxTzTVBytU/arcgis/rest/services/PACIKFBasemap/VectorTileServer/resources/styles/../fonts/{fontstack}/{range}.pbf',
    'sources':
    {
        'esri-satellite':
        {
            'type': 'raster',
            'tiles': ['https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'],
            'tileSize': 256
        },
        'google-satellite':
        {
            'type': 'raster',
            'tiles': ['https://khms3.google.com/kh/v=899?x={x}&y={y}&z={z}'],
            'tileSize': 256
        },
        'aqari':
        {
            'type': 'vector',
            'maxzoom': 17,
            'tiles': [`${process.env.HOST}/api/mvt/geodata/{z}/{x}/{y}`]
        },

        'background_hack':
        {
            'type': 'geojson',
            'data':
            {
                'type': 'Feature',
                'geometry':
                {
                    'type': 'Polygon',
                    'coordinates': [
                        [
                            [46.451693857, 28.424948725],
                            [46.451693857, 30.203262205],
                            [48.87702497, 30.203262205],
                            [48.87702497, 28.424948725],
                            [46.451693857, 28.424948725]
                        ]
                    ]
                }
            }
        },
        'aqari-raster':
        {
            'type': 'raster',
            'tiles': [`${process.env.HOST}/raster_tiles/{z}/{x}/{y}.png`],
            'tileSize': 512,
            'minzoom': 8,
            'maxzoom': 20,
        },
    },

    'layers': [
        {
            'id': 'background',
            'type': 'fill',
            'minzoom': 8,
            'source': 'background_hack',
            'paint':
            {
                'fill-color': 'hsl(35, 32%, 91%)'
            }
        },
        {
            'id': 'country',
            'type': 'fill',
            'minzoom': 8,
            'source': 'aqari',
            'source-layer': 'country',
            'paint':
            {
                'fill-color': '#E2E2E2'
            }
        },
        {
            'id': 'landuse',
            'type': 'fill',
            'minzoom': 9,
            'source': 'aqari',
            'source-layer': 'landuse',
            'paint':
            {
                'fill-color': [
                   'match', ['get', 'symbol'],
                   1,             '#435969',
                   2,             '#c2c2c2',
                   3,             '#bac99c',
                   '#ccc'
                ],
                'fill-opacity': 0.5
            }
        },
        {
            'id': 'sea',
            'type': 'fill',
            'minzoom': 8,
            'source': 'aqari',
            'source-layer': 'sea',
            'paint':
            {
                'fill-color': '#8cd5ff'
            }
        },
        {
            'id': 'governorate_line',
            'type': 'line',
            'visibility': 'visible',
            'source': 'aqari',
            'source-layer': 'governorate_line',
            'minzoom': 8,
            'maxzoom': 10,
            'layout': {},
            'paint':
            {
                'line-opacity': 1.0,
                'line-color': '#910e38',
                // 'line-opacity': 0.8,
                // 'line-width': 4,
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    8,
                    4
                ]
            }
        },
        {
            'id': 'neighborhood_line',
            'type': 'line',
            'source': 'aqari',
            'source-layer': 'neighborhood_line',
            'visibility': 'visible',
            'minzoom': 10,
            'maxzoom': 15,
            'layout': {},
            'paint':
            {
                'line-color': '#8a715e',
                'line-opacity': 1.0,
                // 'line-opacity': 0.4,
                'line-width': 2
            }
        },
        {
            'id': 'block_line',
            'type': 'line',
            'minzoom': 13,
            'maxzoom': 18,
            'source': 'aqari',
            'source-layer': 'block_line',
            'layout': {},
            'paint':
            {
                'line-color': '#0e141f',
                'line-opacity': 0.2,
                // 'line-opacity': 0.8,
                'line-width': 0.5
            }
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // #################################### road_paci_line
        // ##################################################################
        // ##################################################################
        // ##################################################################

        {
            'id': 'road_paci_line4_case',
            'type': 'line',
            'source': 'aqari',
            'source-layer': 'road_paci_line_all_zoom4',
            'minzoom': 14,
            'layout': {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint': {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    14,
                    6,
                    18,
                    40
                ],
                'line-color': '#d6d9e6',
                'line-opacity': [
                    'interpolate',
                    ['exponential',1.5],
                    ['zoom'],
                    14,0.2,
                    15,1.0
                ],
            }
        }, 

        {
            'id': 'road_paci_line4',
            'type': 'line',
            'source': 'aqari',
            'source-layer': 'road_paci_line_all_zoom4',
            'minzoom': 14,
            'layout': {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint': {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    14,
                    4,
                    18,
                    36
                ],
                'line-color': 'hsl(0, 0%, 100%)',
                'line-opacity': [
                    'interpolate',
                    ['exponential',1.5],
                    ['zoom'],
                    14,0.2,
                    15,1.0
                ],
            }
        }, 

        {
            'id': 'road_paci_line3_case',
            'type': 'line',
            'source': 'aqari',
            'source-layer': 'road_paci_line_all_zoom3',
            'minzoom': 13,
            'layout': {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint': {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    13,
                    3.3,
                    18,
                    30
                ],
                'line-color': '#ffffff'
            }
        }, 

        {
            'id': 'road_paci_line3',
            'type': 'line',
            'source': 'aqari',
            'source-layer': 'road_paci_line_all_zoom3',
            'minzoom': 13,
            'layout': {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint': {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    13,
                    2,
                    18,
                    26
                ],
                'line-color': '#ECDDAC'
            }
        }, 


        {
            "id":"road_paci_line_all_zoom2_case",
            "type":"line",
            "source":"aqari",
            "source-layer":"road_paci_line_all_zoom2",
            "minzoom":10,
            // "maxzoom":12,
            "layout":{
               "line-cap":"round",
               "line-join":"round"
            },
            "paint":{
                "line-color":'hsl(0, 0%, 100%)',
           
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    10,
                    2,
                    12,
                    4,
                    20,
                    36
                ],
            }
        },
      
        {
            "id":"road_paci_line_all_zoom2",
            "type":"line",
            "source":"aqari",
            "source-layer":"road_paci_line_all_zoom2",
            "minzoom":9,
            // "maxzoom":12,
            "layout":{
                "line-cap":"round",
                "line-join":"round"
            },
            "paint":{
                "line-color":"#EBB974",
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    1,
                    12,
                    3,
                    20,
                    32
                ],
            }
        },

        {
            "id":"road_paci_line_all_zoom1_case",
            "type":"line",
            "source":"aqari",
            "source-layer":"road_paci_line_all_zoom1",
            "minzoom":10,
            // "maxzoom":12,
            "layout":{
               "line-cap":"round",
               "line-join":"round"
            },
            "paint":{
                "line-color":'hsl(0, 0%, 100%)',
           
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    10,
                    2,
                    12,
                    3,
                    20,
                    124
                ],
            }
        },
      
        {
            "id":"road_paci_line_all_zoom1",
            "type":"line",
            "source":"aqari",
            "source-layer":"road_paci_line_all_zoom1",
            "minzoom":9,
            // "maxzoom":12,
            "layout":{
                "line-cap":"round",
                "line-join":"round"
            },
            "paint":{
                "line-color":"#f2934a",
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    1,
                    12,
                    2,
                    20,
                    120
                ],
            }
        },


        {
            "id":"road_paci_line_low_zoom2_case",
            "type":"line",
            "source":"aqari",
            "source-layer":"road_paci_line_low_zoom2",
            "minzoom":10,
            "maxzoom":12,
            "layout":{
               "line-cap":"round",
               "line-join":"round"
            },
            "paint":{
                "line-color":'hsl(0, 0%, 100%)',
           
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    10,
                    2,
                    12,
                    4
                ],
            }
        },
      
        {
            "id":"road_paci_line_low_zoom2",
            "type":"line",
            "source":"aqari",
            "source-layer":"road_paci_line_low_zoom2",
            "minzoom":9,
            "maxzoom":12,
            "layout":{
                "line-cap":"round",
                "line-join":"round"
            },
            "paint":{
                "line-color":"#EBB974",
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    1,
                    12,
                    3
                ],
            }
        },

        {
            "id":"road_paci_line_low_zoom1_case",
            "type":"line",
            "source":"aqari",
            "source-layer":"road_paci_line_low_zoom1",
            "minzoom":10,
            "maxzoom":12,
            "layout":{
               "line-cap":"round",
               "line-join":"round"
            },
            "paint":{
                "line-color":'hsl(0, 0%, 100%)',
           
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    10,
                    2,
                    12,
                    4
                ],
            }
        },
      
        {
            "id":"road_paci_line_low_zoom1",
            "type":"line",
            "source":"aqari",
            "source-layer":"road_paci_line_low_zoom1",
            "minzoom":9,
            "maxzoom":12,
            "layout":{
                "line-cap":"round",
                "line-join":"round"
            },
            "paint":{
                "line-color":"#f2934a",
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    1,
                    12,
                    3
                ],
            }
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // #################################### Roads
        // ##################################################################
        // ##################################################################
        // ##################################################################

        {
            'id': 'road-4-case',
            'type': 'line',
            'minzoom': 14,
            'source': 'aqari',
            'source-layer': 'road_polygon4',
            'layout':
            {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint':
            {
                'line-width': [
                    'interpolate',
                    ['exponential',1.5],
                    ['zoom'],
                    9,2,
                    14,4,
                    18,8
                ],
                'line-opacity': [
                    'interpolate',
                    ['exponential',1.5],
                    ['zoom'],
                    14,0.2,
                    15,1.0
                ],
                'line-color': '#d6d9e6'
            }
        },
        {
            'id': 'road-4',
            'type': 'fill',
            'minzoom': 14,
            'source': 'aqari',
            'source-layer': 'road_polygon4',
            'layout': {},
            'paint':
            {
                'fill-antialias': false,
                'fill-opacity': [
                    'interpolate',
                    ['exponential',1.5],
                    ['zoom'],
                    14,0.2,
                    15,1.0
                ],
                'fill-color': '#FFFFFF'
            }
        },
        {
            'id': 'road-4-outline',
            'type': 'line',
            'minzoom': 14,
            'source': 'aqari',
            'source-layer': 'road_polygon4',
            'layout':
            {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint':
            {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    1,
                    14,
                    2,
                    18,
                    4
                ],
                'line-opacity': [
                    'interpolate',
                    ['exponential',1.5],
                    ['zoom'],
                    14,0.2,
                    15,1.0
                ],
                'line-color': '#FFFFFF'
            }
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // ##################################################################
        // ##################################################################
        // ##################################################################

        {
            'id': 'road-3-case',
            'type': 'line',
            'minzoom': 13,
            'source': 'aqari',
            'source-layer': 'road_polygon3',
            'layout':
            {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint':
            {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    2,
                    14,
                    4,
                    18,
                    8
                ],
                'line-color': '#ffffff'
            }
        },
        {
            'id': 'road-3',
            'type': 'fill',
            'minzoom': 13,
            'source': 'aqari',
            'source-layer': 'road_polygon3',
            'layout': {},
            'paint':
            {
                'fill-antialias': false,
                'fill-color': '#ECDDAC'
            }
        },
        {
            'id': 'road-3-outline',
            'type': 'line',
            'minzoom': 13,
            'source': 'aqari',
            'source-layer': 'road_polygon3',
            'layout':
            {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint':
            {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    1,
                    14,
                    2,
                    18,
                    4
                ],
                'line-color': '#ECDDAC'
            }
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // ##################################################################
        // ##################################################################
        // ##################################################################

        {
            'id': 'road-2-case',
            'type': 'line',
            'minzoom': 12,
            'source': 'aqari',
            'source-layer': 'road_polygon2',
            'layout':
            {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint':
            {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    2,
                    14,
                    4,
                    18,
                    8
                ],
                'line-color': 'hsl(0, 0%, 100%)'
            }
        },
        {
            'id': 'road-2',
            'type': 'fill',
            'minzoom': 12,
            'source': 'aqari',
            'source-layer': 'road_polygon2',
            'layout': {},
            'paint':
            {
                'fill-antialias': false,
                'fill-color': '#EBB974'
            }
        },
        {
            'id': 'road-2-outline',
            'type': 'line',
            'minzoom': 12,
            'source': 'aqari',
            'source-layer': 'road_polygon2',
            'layout':
            {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint':
            {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    1,
                    14,
                    2,
                    18,
                    4
                ],
                'line-color': '#EBB974'
            }
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // ##################################################################
        // ##################################################################
        // ##################################################################


        {
            'id': 'road-1-case',
            'type': 'line',
            'minzoom': 12,
            'source': 'aqari',
            'source-layer': 'road_polygon1',
            'layout':
            {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint':
            {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    2,
                    14,
                    4,
                    18,
                    8
                ],
                // 'line-opacity': [
                //     'interpolate',
                //     ['exponential',0.5],
                //     ['zoom'],
                //     8,0.0,
                //     9,1.0
                // ],
                'line-color': 'hsl(0, 0%, 100%)'
            }
        },
        {
            'id': 'road-1',
            'type': 'fill',
            'minzoom': 12,
            'source': 'aqari',
            'source-layer': 'road_polygon1',
            'layout': {},
            'paint':
            {
                'fill-antialias': false,
                // 'fill-opacity': [
                //     'interpolate',
                //     ['exponential',0.5],
                //     ['zoom'],
                //     8,0.0,
                //     9,1.0
                // ],
                'fill-color': '#f2934a'
            }
        },
        {
            'id': 'road-1-outline',
            'type': 'line',
            'minzoom': 12,
            'source': 'aqari',
            'source-layer': 'road_polygon1',
            'layout':
            {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint':
            {
                'line-width': [
                    'interpolate',
                    [
                        'exponential',
                        1.5
                    ],
                    [
                        'zoom'
                    ],
                    9,
                    1,
                    14,
                    2,
                    18,
                    4
                ],
                // 'line-opacity': [
                //     'interpolate',
                //     ['exponential',0.5],
                //     ['zoom'],
                //     8,0.0,
                //     9,1.0
                // ],
                'line-color': '#f2934a'
            }
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // #################################### Road number
        // ##################################################################
        // ##################################################################
        // ##################################################################

        {
            'id': 'road_number_secondary',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'road_number_secondary',
            'minzoom': 13,
            // 'maxzoom': 14,
            'layout':
            {
                'text-font': [
                    'Roboto Condensed Bold'
                ],
                'text-size': 10.6667,
                'text-field': '{name}',
                'symbol-placement': 'line',
                'symbol-spacing': 288,
                'icon-image': 'road_number_case',
                'icon-offset': [0,-1],
                'icon-rotation-alignment': 'viewport',
                'text-rotation-alignment': 'viewport'
            },
            'paint':
            {
                //'text-color':'#7239b3'
                'text-color': '#696B63'
            }
        },

        {
            'id': 'road_number_primary',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'road_number_primary',
            'minzoom': 9,
            // 'maxzoom': 12,
            'layout':
            {
                'text-font': [
                    'Roboto Condensed Bold'
                ],
                'text-size': 10.6667,
                'text-field': '{name}',
                'symbol-placement': 'line',
                'symbol-spacing': 1192,
                'icon-image': 'road_number_case',
                'icon-offset': [0,-1],
                'icon-rotation-alignment': 'viewport',
                'text-rotation-alignment': 'viewport'
            },
            'paint':
            {
                //'text-color':'#FF0000'
                'text-color': '#696B63'
            },
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // #################################### Road names
        // ##################################################################
        // ##################################################################
        // ##################################################################

        {
            'id': 'road-1_labels',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'road_name_line1',
            'minzoom': 11,
            'layout':
            {
                'text-font': [
                    'Arial Regular'
                ],
                'text-size': 14,
                'text-field': '{name}',
                'text-optional': true,
                'text-allow-overlap': false,
                'symbol-placement': 'line',
                'symbol-spacing': 1200
            },
            'paint':
            {
                'text-color': '#000000',
                'text-halo-color': [
                    'match',
                    'hsla(0, 0%, 100%, 0.75)',
                    'ferry',
                    'hsl(196, 80%, 70%)',
                    'hsl(0, 0%, 100%)'
                ],
                'text-halo-width': 1,
                'text-halo-blur': 0
            }
        },

        {
            'id': 'road-2_labels',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'road_name_line2',
            'minzoom': 12,
            'layout':
            {
                'text-size': 13,
                'text-font': [
                    'Arial Regular'
                ],
                'text-optional': true,
                'text-field': '{name}',
                'text-allow-overlap': false,
                'symbol-placement': 'line',
                'symbol-spacing': 1200
            },
            'paint':
            {
                'text-color': '#000000',
                'text-halo-blur': 0,
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.0
            }
        },

        {
            'id': 'road-3_labels',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'road_name_line3',
            'minzoom': 13,
            'layout':
            {
                'text-size': 12,
                'text-font': [
                    'Arial Regular'
                ],
                'text-optional': true,
                'text-field': '{name}',
                'text-allow-overlap': false,
                'symbol-placement': 'line',
                'symbol-spacing': 1200
            },
            'paint':
            {
                'text-color': '#000000',
                'text-halo-blur': 0,
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.0
            }
        },

        {
            'id': 'road-4_labels',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'road_name_line4',
            'minzoom': 14,
            'layout':
            {
                'text-size': 11,
                'text-font': [
                    'Arial Regular'
                ],
                'text-optional': true,
                'symbol-placement': 'line',
                'symbol-spacing': 1200,
                'text-field': '{name}'
            },
            'paint':
            {
                'text-color': '#000000',
                'text-halo-blur': 0,
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.0
            }
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // #################################### Satellite
        // ##################################################################
        // ##################################################################
        // ##################################################################

        {
            id: 'esri-satellite',
            type: 'raster',
            source: 'esri-satellite',
            minzoom: 6,
            maxzoom: 20,
            layout:
            {
                visibility: 'none'
            },
        },
        {
            id: 'google-satellite',
            type: 'raster',
            source: 'google-satellite',
            minzoom: 8,
            //          maxzoom: 20,
            layout:
            {
                visibility: 'none'
            },
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // #################################### Parcels
        // ##################################################################
        // ##################################################################
        // ##################################################################

        {
            'id': 'parcel',
            'type': 'fill',
            'minzoom': 15,
            'source': 'aqari',
            'source-layer': 'parcel',
            'layout': {},
            'paint':
            {
                // 'fill-opacity': 0.2,
                'fill-opacity': [
                    'interpolate',
                    ['exponential',1.5],
                    ['zoom'],
                    15,0.1,
                    16,0.2
                ],
                'fill-color': [
                    'match', ['get', 'symbol'],
                    0, '#f9c440',
                    1, '#f37329',
                    2, '#715344',
                    3, '#c6262e',
                    4, '#de3e80',
                    5, '#3689e6',
                    6, '#28bca3',
                    7, '#ED7D31',
                    8, '#5c5c5c',
                    9, '#68b723',
                    10, '#a56de2',
                    11, '#910e38',
                    // If no type mathed then it will be:
                    '#ccc'
                ],
                'fill-outline-color': '#1c1c1c'                
            }
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // #################################### Измеряшки участков | НАЧАЛО
        // ##################################################################
        // ##################################################################
        // ##################################################################

        {
            'id': 'parcel_measure_point',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'parcel_measure_point',
            'minzoom': 18.3,
            'layout':
            {
                'text-rotate': {
                    'property':'label_angle',
                    'default':0,
                    'type':'identity'
                },
                'text-size': 10,
                'text-font': [
                    'Arial Regular'
                ],
                // 'text-anchor': 'center',
                'symbol-avoid-edges': true,
                // 'symbol-placement': 'line-center',

                // 'text-variable-anchor': ['top'],
                // 'text-radial-offset': 20,

                'text-field': '{name}'
            },
            'paint':
            {
                // 'text-translate': [10,20],
                // 'text-translate-anchor': 'viewport',
                'text-color': '#000000',
                'text-halo-color': [
                    'match',

                    'hsla(0, 0%, 100%, 0.75)',
                    'ferry',
                    'hsl(196, 80%, 70%)',
                    'hsl(0, 0%, 100%)'
                ],
                'text-halo-width': 1,
                'text-halo-blur': 0
            }
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // #################################### Administrative Labels
        // ##################################################################
        // ##################################################################
        // ##################################################################
        {
            'id': 'island_point',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'island_point',
            'visibility': 'visible',
            'minzoom': 8,
            'maxzoom': 18,
            'layout':
            {
                'text-size': 22,
                'text-font': [
                    'Arial Regular'
                ],
                // 'text-justify': 'auto',
                'text-padding': 0,
                'text-allow-overlap': false,
                'text-field': '{name}'
            },
            'paint':
            {
                'text-color': '#4e8090',
                'text-halo-color': [
                    'match',

                    'hsla(0, 0%, 100%, 0.75)',
                    'ferry',
                    'hsl(196, 80%, 70%)',
                    'hsl(0, 0%, 100%)'
                ],
                'text-halo-width': 1,
                'text-halo-blur': 0
            }
        },
        {
            'id': 'governorate_point',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'governorate_point',
            'visibility': 'visible',
            'minzoom': 8,
            'maxzoom': 10,
            'layout':
            {
                'text-size': 22,
                'text-font': [
                    'Arial Regular'
                ],
                // 'text-justify': 'auto',
                'text-padding': 0,
                'text-allow-overlap': false,
                'text-field': '{name}'
            },
            'paint':
            {
                'text-color': '#910e38',
                'text-halo-color': [
                    'match',

                    'hsla(0, 0%, 100%, 0.75)',
                    'ferry',
                    'hsl(196, 80%, 70%)',
                    'hsl(0, 0%, 100%)'
                ],
                'text-halo-width': 1,
                'text-halo-blur': 0
            }
        },
        {
            'id': 'neighborhood_point',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'neighborhood_point',
            'visibility': 'visible',
            'minzoom': 10,
            'maxzoom': 13,
            'layout':
            {
                'text-size': 18,
                'text-font': [
                    'Arial Regular'
                ],
                'text-justify': 'auto',
                'text-allow-overlap': false,
                'text-field': '{name}'
            },
            'paint':
            {
                'text-color': '#3d211b',
                'text-halo-color': [
                    'match',

                    'hsla(0, 0%, 100%, 0.75)',
                    'ferry',
                    'hsl(196, 80%, 70%)',
                    'hsl(0, 0%, 100%)'
                ],
                'text-halo-width': 1,
                'text-halo-blur': 0
            }
        },
        {
            'id': 'block_point',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'block_point',
            'visibility': 'visible',
            'minzoom': 13,
            'maxzoom': 20,
            'layout':
            {
                'text-size': 16,
                'text-font': [
                    'Arial Regular'
                ],
                'text-justify': 'auto',
                'text-allow-overlap': true,
                'text-field': '{name}'
            },
            'paint':
            {
                'text-color': '#0e141f',
                'text-halo-color': [
                    'match',

                    'hsla(0, 0%, 100%, 0.75)',
                    'ferry',
                    'hsl(196, 80%, 70%)',
                    'hsl(0, 0%, 100%)'
                ],
                'text-halo-width': 1,
                'text-halo-blur': 0
            }
        },

        {
            'id': 'place_point',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'place_point',
            'minzoom': 15,
            'maxzoom': 18,
            'layout':
            {
                'text-size': 10,
                'text-font': [
                    'Arial Regular'
                ],
                'text-allow-overlap': false,
                'text-field': '{name}',
            },
            'paint':
            {
                'text-opacity': 1,
                'text-color': '#000000',
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.0,
                'text-halo-blur': 0
            }
        },

        // ##################################################################
        // ##################################################################
        // ##################################################################
        // #################################### parcel point
        // ##################################################################
        // ##################################################################
        // ##################################################################

        {
            'id': 'parcel_point_corner',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'parcel_point_corner',
            'minzoom': 16,
            'layout':
            {
                'text-size': 10,
                'text-font': [
                    'Arial Regular'
                ],
                'text-allow-overlap': false,
                'text-field': '{name}',
            },
            'paint':
            {
                'text-opacity': 1,
                'text-color': [
                    'match', ['get', 'symbol'],
                    0, '#002e99',
                    1, '#206b00',
                    '#ccc'
                ]
                ,
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.0,
                'text-halo-blur': 0
            }
        },
        {
            'id': 'parcel_point',
            'type': 'symbol',
            'source': 'aqari',
            'source-layer': 'parcel_point',
            'minzoom': 19,
            'layout':
            {
                'text-size': 10,
                'text-font': [
                    'Arial Regular'
                ],
                'text-allow-overlap': false,
                'text-field': '{name}',
            },
            'paint':
            {
                'text-opacity': 1,
                'text-color': '#000000',
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.0,
                'text-halo-blur': 0
            }
        },
        {
            'id': 'aqari-raster',
            'type': 'raster',
            'source': 'aqari-raster',
            'minzoom': 8,
            'maxzoom': 20,
            'layout': {
                'visibility': 'none',
            }
        },     
    ]
}; 

export default esri_style;